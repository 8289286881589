import get from 'lodash/get';
import { buildDeliveryOptions } from 'utils/deliveryUtils';
import { requestWithRetry } from 'utils/requestUtils';
import { getCountryCode, getStateCode } from 'utils/checkoutUtils';
import {
  CHECKOUT_HOST_V2, CHECKOUT_STATE, ITEMS_CANNOT_BE_SHIPPED_TO_SELECTED_DESTINATION_ERROR, COUNTRY_NOT_SUPPORTED_ERROR
} from '../../constants';
import { addToast } from './toast';

export const SET_DELIVERY_OPTIONS = 'SET_DELIVERY_OPTIONS';
export const IS_FETCHING_DELIVERY_OPTIONS = 'IS_FETCHING_DELIVERY_OPTIONS';
export const SET_DELIVERY_OPTIONS_ERROR = 'SET_DELIVERY_OPTIONS_ERROR';
export const CLEAR_DELIVERY_OPTIONS = 'CLEAR_DELIVERY_OPTIONS';

export const isFetchingDeliveryOptions = (isFetching) => {
  return {
    type: IS_FETCHING_DELIVERY_OPTIONS,
    isFetching
  };
};

const handleDeliveryOptionsError = (dispatch, setAddressHasBeenEntered) => {
  dispatch(
    addToast(
      `Sorry, we can’t deliver the items to your destination. Please change your destination or contact support to be able to complete the 
          purchase.`, 'danger',
      {
        timeout: 6000,
        position: 'top'
      }
    )
  );
  setAddressHasBeenEntered(false);
};

export const fetchDeliveryOptions = (checkoutId, storeSlug, state, countryName, setAddressHasBeenEntered) => async (dispatch, getState) => {
  try {
    const { checkout } = getState();

    if (checkout.state === CHECKOUT_STATE.SUCCESS) return;

    const stateCode = getStateCode(countryName, state);
    const countryCode = getCountryCode(countryName);

    dispatch(isFetchingDeliveryOptions(true));
    const res = await requestWithRetry({
      url: `${CHECKOUT_HOST_V2}/deliveryOptions`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          id: checkoutId,
          storeSlug,
          stateCode,
          countryCode
        })
      },
      requestName: 'fetchDeliveryOptions'
    });

    const { data } = res;

    if (!data) {
      dispatch({
        type: 'SET_ACTIVE_MODAL',
        modalId: 'afterpay-unavailable-modal',
        props: { setActiveModal: null, className: 'afterpay-unavailable-modal' }
      });
    } else {
      const quotes = get(data, 'attributes.quotes');
      const options = buildDeliveryOptions(quotes);

      dispatch({
        type: SET_DELIVERY_OPTIONS,
        options
      });
    }
  } catch (err) {
    const isDeliveryOptionsError = err[0].code === ITEMS_CANNOT_BE_SHIPPED_TO_SELECTED_DESTINATION_ERROR ||
    err[0].code === COUNTRY_NOT_SUPPORTED_ERROR;

    if (isDeliveryOptionsError) {
      handleDeliveryOptionsError(dispatch, setAddressHasBeenEntered);
    } else {
      dispatch({
        type: 'SET_ACTIVE_MODAL',
        modalId: 'afterpay-unavailable-modal',
        props: { setActiveModal: null, className: 'afterpay-unavailable-modal' }
      });
    }
  } finally {
    dispatch(isFetchingDeliveryOptions(false));
  }
};

export const clearDeliveryOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_DELIVERY_OPTIONS
  });
};

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const TeamTile = styled.div`
  border-radius: 12px;
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 216px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 17%);

  &:hover {
    transform: translateY(-10px);
    transition: transform 0.3s linear;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 140px;
    border-radius: 8px;

    img {
      width: 72px;
    }
  }
`;

const TeamCard = (props) => {
  const {
    teamName,
    teamImage,
    teamColor,
    teamLink,
    teamHidden
  } = props;

  return (
    <Link to={ `/${teamLink}` } style={ { display: teamHidden ? 'none' : '' } }>
      <TeamTile style={ { backgroundColor: teamColor } }>
        <img width="125" src={ teamImage } alt={ teamName } />
      </TeamTile>
    </Link>
  );
};

TeamCard.propTypes = {
  teamName: PropTypes.string,
  teamImage: PropTypes.string,
  teamColor: PropTypes.string,
  teamLink: PropTypes.string,
  teamHidden: PropTypes.bool
};

TeamCard.defaultProps = {
  teamName: '',
  teamImage: '',
  teamColor: '',
  teamLink: '',
  teamHidden: false
};

export default TeamCard;

import React from 'react';
import {
  arrayOf,
  bool,
  func,
  shape,
  string
} from 'prop-types';
import { Link } from 'react-router-dom';
import SocialMediaIcons from 'components/SocialMediaIcons';
import tracker from 'utils/tracking';
import { isExternalUrl } from 'utils/generalUtils';
import { Accordion } from '@springforcreators/propel-ui';
import './FlyOutMenu.scss';

const FlyOutMenu = (props) => {
  const {
    menuItems,
    isOpen,
    classes,
    onFocusBlur,
    showSocialIcons,
    showSubcategories
  } = props;

  const openClass = isOpen ? 'active' : 'inactive';
  const subItemsClass = showSubcategories ? 'with-subcategories' : '';
  const { pathname } = window.location;

  const activeItemClass = (item) => {
    return pathname === `/${item.slug}` ? 'is-active' : '';
  };

  const trackCollectionClicked = (label, url) => {
    tracker.track(
      'header.nav_item.clicked',
      {
        navItemLabel: label,
        navIemUrl: url,
        navItemType: 'collection'
      }
    );
  };

  const trackExternalUrlClicked = (label, url) => {
    tracker.track(
      'header.nav_item.clicked',
      {
        navItemLabel: label,
        navIemUrl: url,
        navItemType: 'external_url'
      }
    );
  };

  const onItemClick = (menuItem) => {
    if (menuItem.action instanceof Function) {
      menuItem.action(menuItem.name, menuItem.slug);
    } else {
      trackCollectionClicked(menuItem.name, menuItem.slug);
    }
  };

  const generateLink = (menuItem) => {
    if (isExternalUrl(menuItem.slug)) {
      return (
        <a
          href={ menuItem.slug }
          target="_blank"
          rel="noopener noreferrer"
          onClick={ () => trackExternalUrlClicked(menuItem.name, menuItem.slug) }
        >
          { menuItem.name }
        </a>
      );
    } else {
      return (
        <Link
          className={ activeItemClass(menuItem) }
          onClick={ onItemClick }
          to={ `/${menuItem.slug}` }
        >
          { menuItem.name }
        </Link>
      );
    }
  };

  const subItems = (items) => {
    return items.map((subItem) => {
      return (
        <div
          className={ `flyout-menu-subitem ${ activeItemClass(subItem) }` }
          key={ subItem.slug }
        >
          { generateLink(subItem) }
        </div>
      );
    });
  };

  const shouldShowSubcategories = item => showSubcategories && item.collections;

  const items = menuItems.map((item) => {
    return (
      <li
        className={ `flyout-menu-item ${activeItemClass(item)} ${subItemsClass}` }
        key={ item.slug }
      >
        { shouldShowSubcategories(item) ?
          (
            <Accordion
              title={ generateLink(item) }
              className="custom-accordion-class"
              content={ subItems(item.collections) }
              defaultOpen={ true }
              closedIcon="ChevronDown"
              openIcon="ChevronUp"
            />
          ) :
          generateLink(item)
        }
      </li>
    );
  });

  return (
    <div
      className={ `flyout-menu ${classes} ${openClass} ${subItemsClass}` }
      onBlur={ onFocusBlur }
    >
      <ul>
        { items }
      </ul>

      { showSocialIcons && <SocialMediaIcons /> }
    </div>
  );
};

FlyOutMenu.defaultProps = {
  classes: '',
  isOpen: false,
  onFocusBlur: () => {}, // No-op
  showSocialIcons: false,
  showSubcategories: false
};

FlyOutMenu.propTypes = {
  classes: string,
  isOpen: bool,
  menuItems: arrayOf(shape({
    name: string,
    slug: string
  })).isRequired,
  onFocusBlur: func,
  showSocialIcons: bool,
  showSubcategories: bool
};

export default FlyOutMenu;

/* eslint-disable no-plusplus */
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@springforcreators/propel-ui';
import { bpProps } from 'utils/responsiveUtils';

const ProductFeedSkeleton = () => {
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));

  const skeletons = [];
  for (let i = 0; i < 4; i++) {
    skeletons.push(
      <div className="product-tile-skeleton" key={ i }>
        <Skeleton
          styles={
            bpIsGT('mobileLg') ?
              { height: '100%', borderRadius: 15 } :
              { height: '60%', borderRadius: 15 }
          }
        />
        <Skeleton
          count={ 3 }
          styles={ { width: '45%', textAlign: 'center', marginTop: 20 } }
        />
      </div>
    );
  }

  return <div className="product-feed-skeleton-container">{skeletons}</div>;
};

export default memo(ProductFeedSkeleton);

import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { getCartLineItems, getCartSize } from 'utils/cartUtils';
import { isDigitalProduct } from 'utils/productUtils';
import { bpProps } from 'utils/responsiveUtils';
import tracker from 'utils/tracking';
import { Link } from 'react-router-dom';

import './QuickAddToCartModal.scss';
import CartPill from 'components/CartPill';
import CheckoutButton from 'components/CheckoutButton';
import { setActiveModal } from 'redux/actions';
import LegacyCheckoutButton from '../../../../pages/CheckoutPage/CheckoutDetails/LegacyCheckoutButton';
import { useCheckout } from '../../../../hooks';

const QuickAddToCartModal = () => {
  const { userCartItem } = useSelector(state => state?.activeModal?.mProps);
  const {
    inventory, checkout, userCart, cartProducts, themeData
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const cartSize = getCartSize(userCart) || '';
  const { setClass } = useSelector(state => ({ ...bpProps(state) }));
  const isButtonDisabled = checkout?.isLoading;
  const cartLineItems = userCartItem ? getCartLineItems(userCart, cartProducts, inventory) : [];
  const handleViewCart = () => {
    tracker.track('quick_add_to_cart_modal.view_cart.clicked', {
      cartSize
    });
    dispatch(setActiveModal(null, { userCartItem }));
  };
  const { usCheckoutOnly } = useCheckout();

  return (
    <>
      { cartLineItems?.length > 0 && (
        <div className="quick__add__cartitems">
          <div className="mb2 quick__add__info">
            <div className="quick__add__notification">
              {' '}
              Added to cart
            </div>
            <CartPill />
          </div>
          { cartLineItems.map(item => (
            <div className="quick__add__item" key={ `${item.sku}-${item.quantityInCart}` }>
              <img
                className={ setClass({ default: 'mr2', mobileLg: 'mr1' }) }
                src={ get(item, 'primaryImages.thumblarge') }
                alt={ `${item.title} ${get(item, 'selectedSize.label')} ${item.colorName}` }
              />
              <div className="quick__add__item__info">
                <div className="quick__add__item__title__and__description">
                  <p className="quick__add__item__title">
                    <span>{ item.title }</span>
                  </p>
                  <p className="typ--b4--updated mb0">{ item.productType }</p>
                  {!isDigitalProduct(item) && (
                    <p className="typ--b4--updated mb0">{ `Size: ${get(item, 'selectedSize.label')}, Color: ${item.colorName}` }</p>
                  )}
                </div>
                <p className="typ--b4--updated mb0 quick__add__item__price">{`$${item.price}`}</p>
              </div>
            </div>
          )) }
        </div>
      ) }
      <div className="quick__add__checkout__btn" data-cy="cart-modal-actions">
        <Link to="/cart" onClick={ handleViewCart }>
          View cart  (
          {cartSize}
          )
        </Link>
        {usCheckoutOnly ?
          (
            <CheckoutButton
              className={ `quick__add__btn ${themeData?.previewMode || isButtonDisabled ? 'is-disabled' : ''}` }
              lineItems={ cartLineItems }
            >
              Checkout
            </CheckoutButton>
          ) :
          (
            <LegacyCheckoutButton
              title="Checkout"
              className={ `quick__add__btn ${themeData?.previewMode || isButtonDisabled ? 'is-disabled' : ''}` }
            />
          )
        }
      </div>
    </>
  );
};

export default QuickAddToCartModal;

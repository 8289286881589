/* eslint no-shadow: 0 */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import propTypes from 'prop-types';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import withTracker from 'routes/withTracker';
import PromotionalBanner from 'components/PromotionalBanner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import get from 'lodash/get';
import { setThemeData, setPreviewMode } from 'redux/actions';
import { getPreviewThemeData } from 'themes';
import tracker from 'utils/tracking';
import './MainLayout.scss';
import { bpProps } from 'utils/responsiveUtils';
import AgeVerify from 'components/AgeVerify';
import useFetchProducts from 'utils/getAllProducts';

// Threshold for absolute scroll before hiding the header
// This is primarily to prevent hiding the header when the page
// bounces after over scrolling to the top, which without some
// threshold (or bounce detection) would show and then instantly
// hide the header when enthusiatically scrolling to the top.
const MOBILE_SCROLL_HIDE_THRESHOLD = 15;

const MainLayout = (props) => {
  const {
    activeModal, children, location, setThemeData, setPreviewMode, bpIsGT
  } = props;
  const {
    storeData,
    themeData,
    initThemeData
  } = useGlobalProps();
  const { content } = themeData;
  const notificationEnabled = get(content, 'notification');

  const passedProps = { storeData, location };
  const pageClass = location.pathname.split('/')[1].toLowerCase();
  const [promoList, setPromoList] = useState(null);

  const ldClient = useLDClient();

  const filterPromoCodes = (promoCodes) => {
    const filteredPromoCodes = promoCodes && promoCodes.filter((promo) => {
      if (promo.code && promo.code !== '' && promo.type && promo.type !== '') {
        if (promo.type === 'free_shipping') {
          return true;
        } else if (promo.type === 'percentage' && promo.value > 0) {
          return true;
        } else if (promo.type === 'fixed_value' && promo.value.amount &&
                   promo.value.amount > 0 && promo.value.currency && promo.value.currency === 'USD') {
          return true;
        }
      }
      return false;
    });

    return filteredPromoCodes;
  };

  const { products } = useFetchProducts(); // eslint-disable-line no-unused-vars
  //console.log('storeCollection', products); // eslint-disable-line no-console

  useEffect(() => {
    const promoCodes = get(storeData, 'promoCodes');
    if (promoCodes && filterPromoCodes(promoCodes).length > 0) {
      tracker.track('whitelabel.promo_banner.viewed');
    }
    setPromoList(filterPromoCodes(promoCodes));
  }, []);

  useEffect(() => {
    const { mergedData, isPreviewMode } = getPreviewThemeData(location, initThemeData);
    setThemeData(mergedData);

    if (isPreviewMode && pageClass.length !== 0) {
      setPreviewMode();
    }
  }, [location, setThemeData, initThemeData]);

  useEffect(() => {
    if (ldClient) {
      const context = {
        kind: 'store',
        key: passedProps.storeData.slug,
        name: passedProps.storeData.name
      };
      ldClient.identify(context, null, () => {});
    }
  }, [ldClient]);

  const isMobile = !bpIsGT('mobileLg');
  const [promoContainerClass, setPromoContainerClass] = useState('');
  // Track whether or not content has scrolled past the top of the page
  const [contentTopVisible, setContentTopVisible] = useState(false);
  const onContentTopWaypointTriggered = useCallback((event) => {
    setContentTopVisible(event?.currentPosition === 'inside');
  });
  // Track if mobile auto hide scroll threshold has been met, this may be
  // redundant with the waypoint, but leaving it in just in case there is
  // a case where content starts at the top of the page
  const [mobileScrollThresholdMet, setMobileScrollThresholdMet] = useState(false);
  // Track last scroll direction on mobile
  const [mobileScrollDirection, setMobileScrollDirection] = useState('');
  const previousScroll = useRef(0);
  useEffect(() => {
    // We only care about mobile for mobile scroll direction
    if (!isMobile) {
      return undefined;
    }

    const testMobileScrollThresholdMet = () => {
      setMobileScrollThresholdMet(window.scrollY >= MOBILE_SCROLL_HIDE_THRESHOLD);
    };

    const handleScroll = () => {
      if (window.scrollY > previousScroll.current) {
        setMobileScrollDirection('down');
      } else if (window.scrollY < previousScroll.current) {
        setMobileScrollDirection('up');
      }
      previousScroll.current = window.scrollY;
      testMobileScrollThresholdMet();
    };

    // Set initial scroll threshold
    testMobileScrollThresholdMet();

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('touchmove', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true });
      window.removeEventListener('touchmove', handleScroll, { passive: true });
      // Note we leave the current scroll direction value for the next potential mobile breakpoint
    };
  }, [isMobile]);

  // Determine if a modal is be being shown.
  // The 'object' check is a workaround for an issue in Modal (propel-ui) where it's setting the
  // active modal to an object (containing the id of null) when trying to close the modal.
  // See https://amazesw.atlassian.net/browse/DIS-144
  const activeModalId = activeModal?.id;
  const modalDisplayed = activeModalId && typeof activeModalId !== 'object';

  // Set the 'hidden' promo container class if we last scrolled down on mobile, content has been
  // scrolled off screen, we've passed the scroll distance threshold, and a modal is not being
  // diplayed (the modal check is for dialogs attached to the header, like quick add confirmation)
  useEffect(() => {
    if (isMobile && mobileScrollDirection === 'down' && mobileScrollThresholdMet && !contentTopVisible && !modalDisplayed) {
      setPromoContainerClass('hidden');
    } else {
      setPromoContainerClass('');
    }
  }, [isMobile, mobileScrollDirection, mobileScrollThresholdMet, contentTopVisible, modalDisplayed]);

  if (!get(themeData, 'styles')) {
    return false;
  }

  return (
    <div className={ `page-content ${pageClass}` }>
      <AgeVerify />
      <div className={ `notification-promo-container ${promoContainerClass}` }>
        { notificationEnabled && promoList && promoList.length > 0 && <PromotionalBanner promoCodeList={ promoList } /> }
        { !notificationEnabled && promoList && promoList.length > 0 && <PromotionalBanner promoCodeList={ promoList } /> }
        <Header { ...passedProps } />
      </div>
      <Waypoint
        onEnter={ onContentTopWaypointTriggered }
        onLeave={ onContentTopWaypointTriggered }
      />
      { children }
      <Footer { ...passedProps } />
    </div>
  );
};

const {
  string,
  arrayOf,
  number,
  bool,
  shape,
  node,
  oneOf,
  func
} = propTypes;

MainLayout.propTypes = {
  location: shape({
    hash: string,
    pathname: string,
    search: string
  }),
  children: node.isRequired,
  storeData: shape({
    banner_url: string,
    collections: arrayOf(shape({})),
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: shape({}),
    theme_color: string,
    url: string,
    use_logo: bool,
    location: shape({})
  }).isRequired,
  customHeader: node,
  customFooter: node,
  bpIsGT: func.isRequired,
  setThemeData: func.isRequired,
  setPreviewMode: func.isRequired,
  activeModal: shape({
    id: oneOf([string, null, undefined]) // We only care about id
  })
};

MainLayout.defaultProps = {
  location: {},
  customHeader: null,
  customFooter: null,
  activeModal: undefined
};

const mapStateToProps = state => ({
  ...bpProps(state),
  activeModal: state.activeModal
});

export default withTracker(connect(mapStateToProps, { setThemeData, setPreviewMode })(MainLayout));

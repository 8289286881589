import React from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from 'utils/scrollTo';
import propTypes from 'prop-types';
import { isExternalUrl } from 'utils/generalUtils';

export const CustomLink = (props) => {
  const {
    btnStyles,
    path,
    content,
    openInNewWindow,
    className
  } = props;

  const injectHttp = (pathStr) => {
    let formattedPath = pathStr;
    if (
      pathStr &&
      !pathStr.startsWith('#') &&
      !pathStr.startsWith('http')
    ) formattedPath = `http://${pathStr}`;
    return formattedPath;
  };

  const elem = (isExternalUrl(path) || (path && path.startsWith('#'))) ?
    (
      <a
        onClick={ e => (path && path.startsWith('#')) && scrollTo(path, e) }
        href={ injectHttp(path) }
        target={ isExternalUrl(path) && openInNewWindow ? '_blank' : '' }
        rel="noreferrer"
        style={ btnStyles }
        className={ className }
      >
        { content }
      </a>
    ) :
    (
      <Link to={ path } className={ className } style={ btnStyles }>
        { content }
      </Link>
    );

  return elem;
};

const {
  string,
  shape,
  node,
  oneOfType,
  bool
} = propTypes;

CustomLink.propTypes = {
  btnStyles: shape({}).isRequired,
  path: string.isRequired,
  content: oneOfType([node, string]).isRequired,
  openInNewWindow: bool,
  className: string
};

CustomLink.defaultProps = {
  openInNewWindow: true,
  className: ''
};

export default CustomLink;

import React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@springforcreators/propel-ui';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { getCartLineItems, getCartLineItemsTotal } from 'utils/cartUtils';
import { convertToDollars } from 'utils/moneyUtils';
import Price from 'components/Price';
import propTypes from 'prop-types';
import tracker from 'utils/tracking';
import CheckoutCartItem from './CheckoutCartItem';
import Promocode from '../Promocode';
import { HIDE_PROMO_CODE } from '../../../constants';
import './CheckoutCartInfo.scss';

const lineItemLabels = {
  tax: 'Sales Tax',
  shipping: 'Delivery'
};

const CheckoutCartInfo = ({ showAddressMessage, showPromoCode }) => {
  const {
    userCart,
    cartProducts,
    checkout,
    stores,
    inventory
  } = useSelector(state => state);

  const lineItems = checkout?.costs ? Object.keys(checkout?.costs).reduce((acc, curr) => {
    const current = checkout?.costs[curr] ?? {};
    if (curr !== 'total' && curr !== 'lineItems' && !isEmpty(current)) {
      acc.push({
        id: curr,
        label: lineItemLabels[curr] ? lineItemLabels[curr] : capitalize(curr),
        price: current?.value,
        hide: !current?.value || current?.value === 0
      });
    }
    return acc;
  }, []) : [];

  const cartLineItems = getCartLineItems(userCart, cartProducts, inventory);
  const cartTotal = getCartLineItemsTotal(cartLineItems);
  const total = convertToDollars(checkout?.costs?.total?.value);

  const trackModifyOrderClicked = () => {
    tracker.track('checkout.modify_order.clicked', {
      cartTotal,
      shippingCosts: checkout?.costs?.shipping.value,
      totalCosts: total,
      storeId: stores.id,
      storeSlug: stores.slug,
      promoCode: checkout.promoCode
    });
  };

  const showTotal = (lineItems.some(item => !item.hide));

  return (
    <div className="checkout__cartinfo">
      <div className="checkout__cartinfo__heading">
        <h5>Order summary</h5>
        <Link to="/cart" className="typ--link" onClick={ trackModifyOrderClicked }>
          Modify order
        </Link>
      </div>

      <div className="checkout__cartinfo__inner checkoutsection last">

        <div className="checkout__cartinfo__cartitems">
          { cartLineItems.map(item => (
            <CheckoutCartItem key={ item.sku } { ...item } />
          )) }
        </div>

        <div className="checkout__cartinfo__lineitem typ--bold">
          <span>Subtotal</span>
          <Price value={ cartTotal } />
        </div>

        { showAddressMessage && (
          <p className="typ--b3 mb2">
            Shipping and taxes will be calculated after an address is provided.
          </p>
        ) }

        { !checkout?.fetchingCheckout ?
          (
            <>
              {lineItems.map(item => (
                <div
                  key={ item.id }
                  className={
                    `checkout__cartinfo__lineitem ${item.id} ${item.hide ? 'hide' : ''}
                    ${item?.label === 'Discount' ? 'success' : ''}`
                  }
                >
                  <span>{ item.label }</span>
                  <Price value={ `${item?.label === 'Discount' ? '-' : ''}${convertToDollars(item.price)}` } />
                </div>
              ))}

              { showTotal && (
                <div className="checkout__cartinfo__lineitem checkout__cartinfo__total">
                  { total ? (
                    <>
                      <span>Total</span>
                      <Price value={ total } />
                    </>
                  ) : <></> }
                </div>
              ) }
            </>
          ) : <Loader />
        }
        { !HIDE_PROMO_CODE && (
          <Promocode
            showPromoCode={ showPromoCode }
          />
        ) }
      </div>
    </div>
  );
};

const { bool } = propTypes;
CheckoutCartInfo.propTypes = {
  showAddressMessage: bool.isRequired,
  showPromoCode: bool.isRequired
};

export default CheckoutCartInfo;

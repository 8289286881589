import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import Loader from 'components/Loader';
import MainLayout from 'containers/MainLayout';
import Pagination from 'components/Pagination';
import ProductTile from 'components/ProductTile';
import teams from 'teams.json';
import SEO from 'components/SEO';
import ReactGA from 'react-ga';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import './Collection.scss';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import useFetchProducts from 'utils/getAllProducts';

export const Team = (props) => {
  const {
    location,
    storeData,
    currency,
    storeName,
    sellerId
  } = props;
  const path = location.pathname.split('team/')[1];
  const collectionId = path;
  const { products, loading } = useFetchProducts();
  //Console.log('storeCollection', products); // eslint-disable-line no-console

  // filter teams based on the path
  const team = teams.find(t => t.id === path);
  console.log('team', team); // eslint-disable-line no-console

  const normalizeTerm = (term) => {
    let normalizedTerm = term.replace(/[^a-zA-Z0-9\s]/g, ' ');
    normalizedTerm = normalizedTerm.replace(/\s+/g, ' ');
    normalizedTerm = normalizedTerm.trim();
    return normalizedTerm;
  };
  const generateSearchPatterns = (term) => {
    const normalizedTerm = normalizeTerm(term);
    return [
      normalizedTerm,
      `${normalizedTerm}s`,
      `${normalizedTerm}'s`,
      normalizedTerm.endsWith('s') ? `${normalizedTerm.slice(0, -1)}'s` : null // Remove the 's' from the end and replace with 's
    ].filter(Boolean); // Filter(Boolean) removes any null values from the array
  };
  const normalizeProductName = (name) => {
    return name.replace(/'/g, '');
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const filterProducts = (term) => {
    const searchPatterns = generateSearchPatterns(term);
    const regexPattern = searchPatterns.join('|');
    const regex = new RegExp(`\\b(${regexPattern})\\b`, 'i');
    return products.filter(product => regex.test(normalizeProductName(product.name)));
  };

  const tiles = map(filterProducts(path), (product, i) => (
    <ProductTile product={ product } key={ product.id } list={ collectionId } position={ i } />
  ));
  useEffect(() => {
    ReactGA.event({
      category: 'engagement',
      action: 'view_category',
      label: JSON.stringify({ collectionId })
    }, ['default', 'client']);
  }, [collectionId]);

  const seoDescription = `Shop on ${storeName}! Browse all products from the collection with quality and fast delivery.`;
  useEffect(() => {
    manageZendeskVisibility(true);
  }, []);
  return (
    <>
      <SEO
        title={ `${capitalizeFirstLetter(normalizeTerm(path))} | ${storeName}` }
        description={ seoDescription }
      />
      <MainLayout location={ location } storeData={ storeData }>
        <div className="collection-content content page-width">
          { loading && isEmpty(products) ?
            <Loader /> :
            (
              <Fragment>
                <div className="tile-heading">
                  <h2>
                    { team.name }
                  </h2>
                </div>
                <Pagination
                  items={ tiles }
                  storeName={ storeName }
                  sellerId={ sellerId }
                  currency={ currency }
                  list={ collectionId }
                  anchor=".collection-content"
                  products={ products }
                />
              </Fragment>
            )
          }

        </div>
      </MainLayout>
    </>
  );
};

Team.defaultProps = {
  storeId: null,
  storeCollections: {}
};

const {
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string,
  array
} = propTypes;

Team.propTypes = {
  storeId: string,
  fetchCollection: func.isRequired,
  storeCollections: shape({
    accessories: object,
    products: arrayOf(shape({
      imageUrl: string,
      daysLeft: number,
      id: number,
      name: string,
      price: string,
      productName: string,
      timeLeft: string,
      url: string
    })),
    count: number,
    total_count: number,
    page: number,
    next: string,
    per_page: number,
    facebook_pixel_html: string,
    collection: string
  }),
  location: shape({
    hash: string,
    pathname: string,
    search: string,
    state: object
  }).isRequired,
  storeData: shape({
    banner_url: string,
    collections: array,
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: object,
    theme_color: string,
    url: string,
    use_logo: bool,
    location: object
  }).isRequired,
  localizationData: shape({}).isRequired,
  currency: string.isRequired,
  storeName: string.isRequired,
  sellerId: number.isRequired
};

const mapStateToProps = state => ({
  currency: state.localizationData.buyer_currency,
  storeName: state.stores.name,
  sellerId: state.stores.sellerId
});

export default connect(mapStateToProps)(Team);

import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { number, shape } from 'prop-types';
import { Icon } from '@springforcreators/propel-ui';
import './SocialMediaIcons.scss';

export const SocialMediaIcons = (props) => {
  const { brand, limit, styles } = props;

  const socialMediaIcons = limit ?
    get(brand, 'socialMedia').slice(0, limit) :
    get(brand, 'socialMedia');

  return (
    <ul className="socialicons" style={ styles }>
      { socialMediaIcons.map(account => (
        <li key={ account.id }>
          <a href={ account.url } target="_blank" rel="noopener noreferrer">
            <Icon size={ 18 } name={ account.icon } />
          </a>
        </li>
      )) }
    </ul>
  );
};

SocialMediaIcons.propTypes = {
  brand: shape({}).isRequired,
  limit: number,
  styles: shape({})
};

SocialMediaIcons.defaultProps = {
  limit: null,
  styles: {}
};

const mapStateToProps = state => ({
  brand: get(state, 'themeData.brand')
});

export default connect(mapStateToProps)(SocialMediaIcons);

/*eslint-disable no-shadow */
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Pagination from 'components/Pagination';
import get from 'lodash/get';
import { Button, Skeleton } from '@springforcreators/propel-ui';
import ProductTile from 'components/ProductTile';
import ThemeWrapper from 'containers/ThemeWrapper';
import { Waypoint } from 'react-waypoint';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import tracker from 'utils/tracking';
import teams from 'teams.json';
import TeamCard from 'components/TeamCard';
import { PRODUCT_IMPRESSIONS, pushEvent } from '../../utils/tracking/gtm';
import removeCurrencySymbol from '../../lib';
import { USE_COLLECTIONS } from '../../constants';
import { ProductTileCollections } from '../ProductTileCollections';
import './ProductFeed.scss';
import ProductFeedSkeleton from './ProductFeedSkeleton';

const TeamsBar = styled.div`
  padding-left: calc((100vw - 1440px) / 2 + 32px);
  margin-top: -56px;
  z-index: +1;
  position: relative;
  overflow-y: scroll;
  padding-top: 16px;
  padding-bottom: 16px;

  @media (max-width: 1440px) {
    padding-left: 32px;
  }

  @media (max-width: 768px) {
    padding-left: 16px;
  }
`;

const TeamInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  a {
    &:last-child {
      padding-right: calc((100vw - 1440px) / 2 + 32px);
    }
  }
`;

const ProductFeed = (props) => {
  const {
    storeProducts,
    isFetching,
    storeData,
    fetchProducts,
    themeData,
    getStyles
  } = useGlobalProps();

  const {
    title,
    localizationData,
    storeName,
    previewMode,
    storeListings
  } = props;

  const { layout } = themeData;

  const {
    products,
    page,
    totalPages,
    next
  } = storeProducts;
  const perPage = storeProducts.per_page;
  const showMoreProducts = USE_COLLECTIONS ? page !== totalPages : !!next;
  const { productCacheEnabled } = storeListings;
  const currentPage = page;

  let tiles = [];

  if (products && products.length) {
    tiles = products.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  const handleLoadMore = () => {
    tracker.track(`homepage.load_more.clicked`, { productCount: products.length });
    fetchProducts(currentPage + 1);
  };

  const teamTiles = teams;

  useEffect(() => {
    if (products.length) {
      const latestFetchedProducts = products.slice(products.length - perPage || 0, products.length);
      const productImpressions = latestFetchedProducts.map((product, position) => (
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          list: 'Featured Products',
          position: position + 1,
          dimension8: get(storeData, 'sellerId'),
          dimension9: get(product, 'listingId')
        }
      ));
      const eventBody = {
        currencyCode: get(localizationData, 'buyer_currency'),
        impressions: productImpressions
      };
      pushEvent(PRODUCT_IMPRESSIONS, eventBody);
    }
  }, [products]);

  return (
    <Fragment>
      <div className="anchor" id="featured-products" />
      <TeamsBar>
        <TeamInner>
          {teamTiles.map(team => (
            <TeamCard key={ team.id } teamName={ team.name } teamImage={ team.img } teamColor={ team.color } teamLink={ team.slug } teamHidden={ team.hidden } />
          ))}
        </TeamInner>
      </TeamsBar>
      <div className="product-feed">
        <div className="tile-heading page-width">
          {isFetching && !products?.length ? (
            <Skeleton styles={ { height: 30, width: '45%' } } />
          ) : (
            <h2 style={ getStyles('productList.titleStyles') } data-cy="product-feed-title">
              {title}
            </h2>
          )}
        </div>

        { isFetching && !products?.length && (
          <ProductFeedSkeleton />
        ) }

        { (previewMode || get(layout, 'productList.pagination')) ?
          <Pagination items={ tiles } /> :
          <div className="product-tile-grid page-width" data-cy="product-tile-grid">{ tiles }</div>
        }

        { showMoreProducts && !productCacheEnabled && (
          <>
            { currentPage < 2 ? (
              <Waypoint
                onEnter={
                  (wp) => {
                    if (!isFetching && wp.previousPosition === 'below' && !get(layout, 'productList.pagination')) {
                      const { page } = storeProducts;
                      fetchProducts(page + 1);
                    }
                  }
                }
              />
            ) : (
              <div className="center-content mb4">
                { !isFetching && (
                  <Button
                    onClick={ handleLoadMore }
                  >
                    Load More
                  </Button>
                )}
              </div>
            )}

          </>
        ) }
      </div>
    </Fragment>
  );
};

const { string, shape, bool } = propTypes;

ProductFeed.propTypes = {
  title: string,
  localizationData: shape({}).isRequired,
  storeName: string,
  previewMode: bool.isRequired,
  storeListings: shape({}).isRequired
};

ProductFeed.defaultProps = {
  title: null,
  storeName: ''
};

const mapStateToProps = state => ({
  localizationData: state.localizationData,
  storeName: state.stores.name,
  previewMode: get(state, 'themeData.previewMode'),
  storeListings: get(state, 'storeListings')
});

export default connect(mapStateToProps)(ThemeWrapper(ProductFeed, ['content.productList', 'styles.productList', 'layout.productList']));

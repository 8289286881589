import React from 'react';
import { connect } from 'react-redux';
import { setActiveModal } from 'redux/actions';
import tracker from 'utils/tracking';
import { Icon } from '@springforcreators/propel-ui';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import propTypes from 'prop-types';
import './SizeChartButton.scss';

const SizeChartButton = (props) => {
  const {
    sizeChartData,
    dispatchSetActiveModal,
    details
  } = props;

  const sizeChartUrl = get(details, 'sizeChartUrl', '');

  const sizes = sizeChartData ? Object.keys(sizeChartData) : [];
  const dimensions = sizes.length ? Object.keys(get(sizeChartData, sizes[0])) : [];
  const measurements = sizes.length && dimensions.length ? Object.keys(get(sizeChartData, [sizes[0], dimensions[0]])) : [];

  const isValidSizeChartData = () => {
    if (isEmpty(sizeChartData) || sizeChartData[sizes[0]][dimensions[0]][measurements[0]].length <= 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      { (sizeChartUrl || isValidSizeChartData()) &&
        (
          <>
            <div className="sizechart__modal-btn">
              <button
                type="button"
                onClick={ () => {
                  dispatchSetActiveModal('sizechart-modal');
                  tracker.track('product_page.size_guide.clicked');
                } }
              >
                <Icon name="Ruler" size={ 20 } className="" />
                Size guide
              </button>
            </div>
          </>
        )
      }
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchSetActiveModal: (id, props) => dispatch(setActiveModal(id, props))
});

const { shape, func, object } = propTypes;
SizeChartButton.propTypes = {
  sizeChartData: shape({}).isRequired,
  dispatchSetActiveModal: func.isRequired,
  details: object.isRequired
};

export default connect(null, mapDispatchToProps)(SizeChartButton);


import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Skeleton } from '@springforcreators/propel-ui';
import { useSelector } from 'react-redux';
import MenuItem from 'components/MenuItem';
import './MainMenu.scss';

const MainMenu = ({ menuItems, className }) => {
  const { isFetching, storeProducts } = useSelector(state => state);
  const selectedCategory = window.location.pathname.replace('/', '');
  const categoryCollections = category => category.collections || [];
  const categorySlugs = category => [category.slug, ...categoryCollections(category).map(subCategory => subCategory.slug)];
  const isCategorySelected = category => categorySlugs(category).some(categorySlug => categorySlug === selectedCategory);

  return (
    <div className={ `main-menu ${className}` }>
      <ul className="header-menu">
        { isFetching && !storeProducts?.products?.length ?
          menuItems.map(item => (
            <div style={ { display: 'flex', flexDirection: 'row' } } key={ item.name }>
              <Skeleton styles={ { height: 30, width: 100, borderRadius: 15 } } />
            </div>
          )) :
          menuItems.map(item => (
            <MenuItem
              item={ item }
              key={ item.slug }
              isSelected={ isCategorySelected(item) }
            />
          ))}
      </ul>
    </div>
  );
};

MainMenu.propTypes = {
  className: string,
  menuItems: arrayOf(shape({
    name: string,
    slug: string
  })).isRequired
};

MainMenu.defaultProps = {
  className: ''
};

export default MainMenu;

import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { TrackJS } from 'trackjs';
import configureStore from './redux/createStore';
import ConnectedApp from './containers/App';
import 'styles/core.scss';

import '@springforcreators/propel-ui/dist/styles/base-styles.scss';
import '@springforcreators/propel-ui/dist/styles/ts-styles.scss';
import * as serviceWorker from './serviceWorker';

// Initialize track js
TrackJS.install({
  token: process.env.REACT_APP_TRACK_JS_TOKEN,
  application: process.env.REACT_APP_TRACK_JS_APPLICATION
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={ configureStore() }>
        <ConnectedApp />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

if ('IntersectionObserver' in window) {
  const observer = new IntersectionObserver((entries) => {
    document.body.classList.toggle('scrolled', !entries[0].isIntersecting);
  });
  observer.observe(document.getElementById('scroll-mark'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

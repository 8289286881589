import React, { useState } from 'react';
import { SelectField, InputField } from '@springforcreators/propel-ui';
import propTypes from 'prop-types';
import './QtyField.scss';

export const QtyField = (props) => {
  const {
    onChange,
    maxQty,
    value,
    id,
    className
  } = props;

  const [useNumberField, setUseNumberField] = useState(value >= maxQty);
  const [focus, setFocus] = useState(false);

  const quantityOptions = new Array(maxQty).fill()
    .map((qty, index) => {
      return { id: index, label: index === (maxQty - 1) ? `${maxQty}+` : `${index + 1}` };
    });

  const changeHandler = (val) => {
    if (val === `${parseInt(maxQty)}+`) {
      setUseNumberField(true);
      setFocus(true);
    } else {
      onChange(val);
    }
  };

  return (
    <div className={ `qtyfield ${className}` } data-cy="qty-field">
      { useNumberField ?
        (
          <InputField
            id={ id }
            label="QTY"
            value={ value }
            onChange={ event => onChange(event.value) }
            focus={ focus }
          />
        ) :

        (
          <>
            <SelectField
              items={ quantityOptions }
              label="QTY"
              onChange={ (index, item) => changeHandler(item?.label) }
              defaultValue={ quantityOptions.find(opt => parseInt(opt.label) === value)?.label }
            />
          </>
        )
      }
    </div>
  );
};

const { number, func, string } = propTypes;
QtyField.propTypes = {
  maxQty: number,
  onChange: func.isRequired,
  value: number.isRequired,
  id: string,
  className: string
};

QtyField.defaultProps = {
  maxQty: 10,
  id: '',
  className: ''
};

export default QtyField;

import React, { useEffect, useRef } from 'react';
import { number, string, oneOfType } from 'prop-types';
import './FanValidation.scss';
import { useSelector } from 'react-redux';
import { bpProps } from 'utils/responsiveUtils';
import tracker from 'utils/tracking';
import { Icon } from '@springforcreators/propel-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const FanValidation = ({ orders }) => {
  const { showFanValidation } = useFlags();
  let showFlag = true;
  if (showFanValidation !== undefined && !showFanValidation) { showFlag = false; }
  const shouldDisplay = Number(orders) > 100 && showFlag;
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  const mainContainer = useRef();
  useEffect(() => {
    if (mainContainer.current) {
      if (bpIsGT('mobileLg')) {
        mainContainer.current.style.opacity = 1;
      } else {
        mainContainer.current.style.opacity = '.9';
      }
    }
  }, [mainContainer]);

  useEffect(() => {
    if (shouldDisplay) {
      tracker.track('fanvalidation.viewed');
    }
  }, [shouldDisplay]);
  return (shouldDisplay &&
    (
      <div className="fanValidationWrapper">
        <div className="fanValidationContainer" ref={ mainContainer }>
          <Icon name="BellOn" size="16" className="fanValidationAlarmIcon" />
          <span className="fanValidationBold">
            {`${orders} fans`}
          </span>
          &nbsp;have purchased this merchandise recently.
        </div>
      </div>
    )
  );
};
FanValidation.propTypes = {
  orders: oneOfType([string, number])
};

FanValidation.defaultProps = {
  orders: 0
};

export default FanValidation;

/*eslint-disable no-irregular-whitespace, max-len*/

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MainLayout from 'containers/MainLayout';
import styled from 'styled-components';
import teams from 'teams.json';
import TeamCard from 'components/TeamCard';
import SEO from 'components/SEO';
import get from 'lodash/get';

const TeamsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, minmax(0px, 1fr));
    gap: 16px;
    margin-bottom: 72px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, minmax(0px, 1fr));
    }

    div {
      width: 100%;
    }
`;

const TeamsPage = (props) => {
  const {
    creator,
    storeName,
    location,
    storeData
  } = props;

  const seoDescription = `Shop our Teams on ${storeName}! Browse all products from the collection with quality and fast delivery.`;
  const teamTiles = teams;
  console.log('teamTiles', teamTiles); // eslint-disable-line no-console

  return (
    <>
      <SEO
        title={ `Teams | ${get(creator, 'storeName')}` }
        description={ seoDescription }
      />
      <MainLayout location={ location } storeData={ storeData }>
        <div className="collection-content content page-width">
          <Fragment>
            <div className="tile-heading"><h2>Teams</h2></div>
            <TeamsContainer>
              {teamTiles.map(team => (
                <TeamCard key={ team.id } teamName={ team.name } teamImage={ team.img } teamColor={ team.color } teamLink={ team.slug } teamHidden={ team.hidden } />
              ))}
            </TeamsContainer>
          </Fragment>
        </div>
      </MainLayout>
    </>
  );
};

const { string, shape, object } = propTypes;
TeamsPage.propTypes = {
  creator: shape({
    company: string,
    name: string,
    domain: string,
    email: string,
    address: shape({
      country: string,
      postal_code: string,
      state: string,
      city: string,
      address_line_1: string,
      address_line_2: string
    })
  }).isRequired,
  storeName: string.isRequired,
  location: shape({
    hash: string,
    pathname: string,
    search: string,
    state: object
  }).isRequired,
  storeData: shape({
    location: object
  }).isRequired
};

const mapStateToProps = state => ({
  currency: state.localizationData.buyer_currency,
  storeName: state.stores.name,
  sellerId: state.stores.sellerId
});

export default connect(mapStateToProps)(TeamsPage);

import React, { Component } from 'react';
import propTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { scrollTo } from 'utils/scrollTo';
import ordinal from 'ordinal';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PRODUCT_IMPRESSIONS, pushEvent } from 'utils/tracking/gtm';
import { Icon } from '@springforcreators/propel-ui';
import './Pagination.scss';
import removeCurrencySymbol from '../../lib';

/**
 * Converts an array of items into a 2D array containing
 * the same data 'chunked' into arrays with a length of
 * pageSize, with the last item in the array containing
 * anything left over
 *
 * @param {array} data - Data to chunk
 * @param {number} pageSize - Size of each chunk
 * @returns {array[]} Returns a 2D array of chunked data
 */
function paginateData(data, pageSize) {
  return chunk(data, pageSize);
}

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPageIndex: 0,
      initialPageLoaded: false
    };

    this.updatePage = this.updatePage.bind(this);
    this.getAdjacentPages = this.getAdjacentPages.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const products = get(this.props, 'products', []);

    if (!isEmpty(products)) {
      const { currentPageIndex, initialPageLoaded } = this.state;

      // Only push the first page once to GTM
      if (!initialPageLoaded && currentPageIndex === 0) {
        this.pushGTMEvent(products, currentPageIndex);
        this.setState({ initialPageLoaded: true });
      }

      if (
        prevState.currentPageIndex !== currentPageIndex &&
        currentPageIndex > prevState.currentPageIndex
      ) this.pushGTMEvent(products, currentPageIndex);
    }
  }

  /**
   * Constructs an array of the current and adjacent pages
   * @param {array} pages - Array of all pages (for querying length)
   * @returns {array} Returns an array representing the current page range [current -1, current, current +1]
   */
  getAdjacentPages(pages) {
    const { currentPageIndex } = this.state;
    const currentPageString = currentPageIndex + 1;
    return [
      currentPageString - 1,
      currentPageString,
      currentPageString + 1
      // Filters out pages that don't fit within the max range [0, pages.length]
    ].filter(
      num => num === currentPageString || (num > 0 && num < pages.length + 1)
    );
  }

  /**
   * Sets the currentPageIndex to the passed index, and
   * initates a pageScroll to the top of the products list
   * @param {Object} event - Click event object
   * @param {number} index - Index to update to
   */
  updatePage(event, index) {
    const { anchor, scrollContainer } = this.props;
    scrollTo(anchor, event, scrollContainer);
    this.setState({ currentPageIndex: index });
  }

  pushGTMEvent(products, currentPage) {
    const {
      storeName, sellerId, currency, list, pageSize
    } = this.props;
    const items = paginateData(products, pageSize);

    const event = {
      currencyCode: currency,
      impressions: map(get(items, [currentPage], []), (product, i) => {
        return {
          name: product.name,
          id: product.listingId,
          price: removeCurrencySymbol(product.price),
          brand: storeName,
          category: product.productName,
          list,
          position: i + 1,
          dimension8: sellerId,
          dimension9: product.listingId
        };
      })
    };

    pushEvent(PRODUCT_IMPRESSIONS, event);
  }

  render() {
    const { items, pageSize } = this.props;
    const { currentPageIndex } = this.state;

    const pages = paginateData(items, pageSize);
    const adjacentPages = this.getAdjacentPages(pages);
    const isMobileView = window.innerWidth < 767;

    return (
      <div className="pagination">
        <div className="product-tile-grid">{pages[currentPageIndex]}</div>

        {pages.length > 1 && (
          <div className="pagination__controls">
            {/* Shows previous button. Disabled if first page is active */}
            <button
              type="button"
              aria-label="Previous page"
              className={ `pagination__prev ${
                currentPageIndex === 0 ? 'is-disabled' : ''
              }` }
              onClick={ event => this.updatePage(event, currentPageIndex - 1) }
            >
              <Icon name="ChevronLeft" size={ 18 } className="ml1 mr1" />
              Prev
            </button>

            {/* Shows first page link (always 1). Only show if
            1 is not within the current page range */}
            {!isMobileView && currentPageIndex >= 2 && (
              <button
                type="button"
                aria-label="1st page"
                onClick={ event => this.updatePage(event, 0) }
              >
                1 &nbsp;&hellip;
              </button>
            )}

            {/* Show list of all pages in the current range
            [current -1, current, current +1] */}
            {adjacentPages.map(page => (
              <button
                type="button"
                aria-label={ `${ordinal(page)} page` }
                className={ currentPageIndex + 1 === page ? 'is-active' : '' }
                key={ page }
                onClick={ event => this.updatePage(event, page - 1) }
              >
                {page}
              </button>
            ))}

            {/* Shows last page if it is not within current range */}
            {!isMobileView && currentPageIndex <= pages.length - 3 && (
              <button
                type="button"
                aria-label="Last page"
                onClick={ event => this.updatePage(event, pages.length - 1) }
              >
                &hellip;&nbsp;
                {pages.length}
              </button>
            )}

            {/* Shows next page button. Disabled if last page is active. */}
            <button
              type="button"
              aria-label="Next page"
              className={ `pagination__next ${
                currentPageIndex === pages.length - 1 ? 'is-disabled' : ''
              }` }
              onClick={ event => this.updatePage(event, currentPageIndex + 1) }
            >
              Next
              <Icon name="ChevronRight" size={ 18 } className="ml1 mr1" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

const {
  number, arrayOf, node, string, shape
} = propTypes;
Pagination.propTypes = {
  pageSize: number,
  items: arrayOf(node).isRequired,
  anchor: string,
  scrollContainer: shape({}),
  storeName: string,
  sellerId: number,
  currency: string,
  list: string.isRequired
};

Pagination.defaultProps = {
  pageSize: 12,
  anchor: '#featured-products',
  scrollContainer: document.scrollingElement,
  storeName: '',
  sellerId: 0,
  currency: 'USD'
};

export default Pagination;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API_HOST } from '../constants';

const CACHE_EXPIRATION_MINUTES = 60; // Cache expiration time in minutes

const useFetchProducts = () => {
  const storedProducts = JSON.parse(localStorage.getItem('products') || '[]');
  const initialLoadingState = storedProducts.length === 0;

  const [products, setProducts] = useState(storedProducts);
  const [loading, setLoading] = useState(initialLoadingState);
  const [error, setError] = useState(null);

  // Fetch localization data from Redux store
  const localizationData = useSelector(state => state.localizationData);
  const currency = localizationData?.buyer_currency;
  const region = localizationData?.buyer_region;

  useEffect(() => {
    const fetchProducts = async (page = 1, accumulatedProducts = []) => {
      try {
        const fulfillmentRegion = region || 'USA';
        const buyerCurrency = currency || '';
        const slug = 'jelles';
        const url = `${API_HOST}/v1/stores/products?slug=${slug}&currency=${buyerCurrency}&region=${fulfillmentRegion}&per=160&page=${page}`;
        const res = await fetch(url, { headers: { Accept: 'application/json' } });

        if (!res.ok) {
          throw new Error('Failed to fetch products');
        }

        const data = await res.json();
        const newProducts = accumulatedProducts.concat(data.products);

        if (data.next) {
          // If there is a next page, fetch the next page
          fetchProducts(page + 1, newProducts);
        } else {
          // If there is no next page, finalize the product list
          setProducts(newProducts);
          localStorage.setItem('products', JSON.stringify(newProducts));
          localStorage.setItem('productsFetchTimestamp', Date.now());
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const shouldFetchProducts = () => {
      const fetchTimestamp = localStorage.getItem('productsFetchTimestamp');
      if (!fetchTimestamp) return true;

      const expirationTime = CACHE_EXPIRATION_MINUTES * 60 * 1000;
      return Date.now() - fetchTimestamp > expirationTime;
    };

    if (shouldFetchProducts()) {
      fetchProducts();
    }
  }, [currency, region]);

  return { products, loading, error };
};

export default useFetchProducts;

/* eslint no-shadow: 0, no-return-await: 0 */
import React, { Component } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import { connect } from 'react-redux';
import { setActiveModal, fetchCartListings } from 'redux/actions';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { bpProps } from 'utils/responsiveUtils';
import propTypes from 'prop-types';
import ThemeWrapper from 'containers/ThemeWrapper';
import MainLayout from 'containers/MainLayout';
import Loader from 'components/Loader';
import CartSummary from 'components/CartSummary';
import CartItem from 'components/CartItem';
import Price from 'components/Price';
import {
  cartListingsAreLoaded,
  getCartLineItems,
  getCartSize,
  getCartTotal,
  getUnavailableItems,
  pruneCart
} from 'utils/cartUtils';
import queryString from 'query-string';
import get from 'lodash/get';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import tracker from 'utils/tracking';
import stripePromise from 'utils/stripeUtils';
import { Elements } from '@stripe/react-stripe-js';
import './ShoppingCart.scss';
import RelatedGrid from '../../components/RelatedGrid';

export class ShoppingCart extends Component {
  async componentDidMount() {
    const {
      fetchCartListings,
      userCart,
      isFetchingCart
    } = this.props;

    await fetchCartListings(userCart);

    if (!isFetchingCart) {
      this.trackCartView();
      this.triggerUnavailableItemsModal();
    }
    manageZendeskVisibility(false);
  }

  componentDidUpdate(prevProps) {
    const {
      isFetchingCart, userCart, cartProducts, inventory
    } = this.props;
    const prevWasFetching = prevProps.storeListings.isFetchingCart;
    const currIsDoneFetching = !isFetchingCart;
    const doneFetching = prevWasFetching && currIsDoneFetching;

    if (doneFetching) {
      const currUserCartLength = Object.keys(pruneCart(userCart)).length;
      const currValidUserCartItemsLength = getCartLineItems(userCart, cartProducts, inventory).length;
      const detectedUnavailableItem = currUserCartLength > currValidUserCartItemsLength;

      if (detectedUnavailableItem) {
        this.trackCartView();
        this.triggerUnavailableItemsModal();
      }
    }
  }

  triggerUnavailableItemsModal() {
    const {
      userCart, cartProducts, dispatchSetActiveModal, storeId, inventory
    } = this.props;

    const unavailableItems = getUnavailableItems(userCart, cartProducts, inventory);
    if (unavailableItems && unavailableItems.length > 0) {
      setTimeout(() => {
        dispatchSetActiveModal('cart-unavailable-items', { items: unavailableItems, storeId });
      }, 400);
    }
  }

  trackCartView() {
    const {
      userCart,
      cartProducts,
      storeId,
      location,
      cookies,
      inventory
    } = this.props;
    const { deviceId } = queryString.parse(location.search);
    const cartLineItems = getCartLineItems(userCart, cartProducts, inventory);
    const cartTotal = <Price value={ getCartTotal(cartLineItems) } />;
    const partnerCookie = cookies.get('_teespring_partner_attribution');

    if (deviceId) tracker.setAmplitudeDeviceId(deviceId);

    tracker.track(
      'cart_page.viewed',
      {
        whitelabelStoreId: storeId,
        total: cartTotal,
        itemsInCart: cartLineItems.map(item => item.sku),
        partner: (partnerCookie && partnerCookie.partner) || null
      }
    );
    ReactGA.event({
      category: 'ecommerce',
      action: 'view_cart',
      label: JSON.stringify({ cartTotal })
    }, ['default', 'client']);
    ReactPixel.pageView();
  }

  render() {
    const {
      location,
      storeData,
      isFetchingCart,
      bpIsLT,
      userCart,
      content,
      cartProducts,
      inventory
    } = this.props;

    const cart = pruneCart(userCart);
    const isLoaded = cartListingsAreLoaded(cart, cartProducts) && !isFetchingCart;
    const cartLineItems = getCartLineItems(cart, cartProducts, inventory);
    const cartSize = getCartSize(cart);
    const cartTotal = <Price value={ getCartTotal(cartLineItems) } />;
    return (
      <MainLayout location={ location } storeData={ storeData }>
        <div className="shopping-cart-content">
          { !isLoaded && <Loader /> }
          { isLoaded && (
            <div className="shopping-cart">
              <div className="shoppingcart__listitems">
                { bpIsLT('tabletMd') ? (
                  <div className="shopping-cart-mobile-header">
                    <div className="shopping-cart-heading">
                      { get(content, 'cart.title') || 'My Cart' }
                    </div>
                    <div>{ `${cartSize} item${cartSize === 1 ? '' : 's'}` }</div>
                  </div>
                ) : (<div className="shopping-cart-heading">{ get(content, 'cart.title') || 'My Cart' }</div>)}
                { cartLineItems.length === 0 ?
                  <p className="cart__empty">Your cart is empty.</p> :
                  (
                    <ul className="cart-item-list">
                      { cartLineItems.map(item => <CartItem item={ item } key={ item.sku } />) }
                    </ul>
                  )
                }
              </div>
              <div className="shoppingcart__summary_wrap">
                <div className="shoppingcart__summary">
                  <Elements stripe={ stripePromise }>
                    <CartSummary
                      cartItems={ cartLineItems }
                      totalInt={ parseFloat(getCartTotal(cartLineItems)) }
                      total={ cartTotal }
                      userCart={ userCart }
                      cartProducts={ cartProducts }
                    />
                  </Elements>
                </div>
              </div>
            </div>
          )}
          {
            cartSize > 0 &&
            (
              <div className="related-grid-cart-summary">
                <RelatedGrid
                  title="You might also like"
                  products={ [] }
                  recommendedShelfEvent="whitelabel.cart_summary.recommended_products.viewed"
                  recommendedShelfProductEvent="whitelabel.cart_summary.recommended_products.clicked"
                />
              </div>
            )
          }
        </div>
      </MainLayout>
    );
  }
}

const {
  array,
  bool,
  shape,
  string,
  func,
  object,
  number,
  instanceOf
} = propTypes;

ShoppingCart.propTypes = {
  isFetchingCart: bool.isRequired,
  storeId: string.isRequired,
  storeData: shape({
    banner_url: string,
    collections: array,
    description: string,
    link_color: string,
    logo_height: number,
    logo_url: string,
    logo_width: number,
    name: string,
    social_identities: object,
    theme_color: string,
    url: string,
    use_logo: bool,
    location: object
  }).isRequired,
  location: shape({
    pathname: string,
    hash: string,
    search: string,
    state: object
  }).isRequired,
  userCart: shape({}).isRequired,
  dispatchSetActiveModal: func.isRequired,
  storeListings: shape({}).isRequired,
  cartProducts: shape({}).isRequired,
  bpIsLT: func.isRequired,
  content: shape({
    cart: shape({
      title: string
    })
  }).isRequired,
  cookies: instanceOf(Cookies).isRequired,
  fetchCartListings: func.isRequired,
  inventory: shape({}).isRequired
};

const mapStateToProps = (state) => {
  const {
    userCart,
    storeListings,
    activeModal,
    cartProducts,
    inventory
  } = state;

  return {
    storeListings,
    userCart,
    ...bpProps(state),
    activeModal,
    isFetchingCart: state.storeListings.isFetchingCart,
    cartProducts,
    inventory
  };
};

export default connect(mapStateToProps, {
  dispatchSetActiveModal: setActiveModal,
  fetchCartListings
})(withRouter(ThemeWrapper(ShoppingCart, ['content'])));
